import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, useStripe } from '@stripe/react-stripe-js';

import CheckoutForm from './CheckoutForm';
import styles from './Common.less';
import { Card, Skeleton, message } from 'antd';
import useQueryParamsOnload from 'hooks/useQueryParamsOnload';
import TextButton from 'routes/AgencyCostManagement/components/TextButton';
import { Get } from 'utils/request';
import { dsersBFFApiHost } from 'common/sysconfig';

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is a public sample test API key.
// Don’t submit any personally identifiable information in requests made with this key.
// Sign in to see your own test API key embedded in code samples.
// test pk
// const pubKey =
//   'pk_test_51MbLRRBu1XO6EMKGsqNRF4MxzPIUan4m6WBXYE991c9lJ2SYf2YUWrzJpy6pE91l0QQWgRbee6mygVLp9gOwJRmL00dHmJ1aLv';

// production pk
const pubKey =
  process.env.BUILD_TARGET !== 'prod'
    ? 'pk_test_51MbLRRBu1XO6EMKGsqNRF4MxzPIUan4m6WBXYE991c9lJ2SYf2YUWrzJpy6pE91l0QQWgRbee6mygVLp9gOwJRmL00dHmJ1aLv'
    : 'pk_live_51MecQABGZXAXoqT6qu7Lpb7pjltbMmkQpjkZdZYmOHPIYLf9pUU1YmQoGIHXuY1qc3OjaO2ejMejO6SaWMY9VFph00UEk1rxeG';
// const stripePromise = loadStripe(
//   'pk_live_51MbLRRBu1XO6EMKGfut7CHvxWitDyXK4kCmEGk8abFGrEcItybTMLGyMUV0LeaNSLl24DoMZTTWpWNqSNnCMCypV00QgrOpJD3'
// );

export default function Stripe() {
  // const [clientSecret, setClientSecret] = useState('');
  const [loading, setLoading] = useState(true);
  const [checkoutInfo, setCheckInfo] = useState({});
  const [showReload, setShowReload] = useState(false);
  const [clientSecret, setClientSecret] = useState('');
  const [stripePromise, setStripePromise] = useState(null);

  const {
    // clientSecret: querySecret,
    transactionId,
    from,
    is_confirm_return,
    urlKey
  } = useQueryParamsOnload();

  useEffect(() => {
    if (stripePromise) {
      stripePromise.catch(e => {
        console.log(e);
        setShowReload(true);
      });
    }
  }, [stripePromise]);

  useEffect(() => {
    if (!transactionId) {
      message.error('Invalid payment link');
      return;
    }
    Get(`${dsersBFFApiHost}/dsers-pay-bff/v1/pay/info/${transactionId}`, {
      isCustomApi: true
      // data: {
      //   transactionId
      // }
    })
      .then(res => {
        if (!res.code && res) {
          // {
          //   "currency": "string",
          //   "price": "string",
          //   "status": "defaultPay",
          //   "transactionId": "string",
          //   "urlKey": "string"
          // }
          setCheckInfo(res);
          setClientSecret(res.urlKey);
          if (!res.urlKey) {
            message.error('Invalid payment link');
          }
          const stripePromise = loadStripe(
            pubKey,
            res.stripeAccountId
              ? {
                  stripeAccount: res.stripeAccountId
                }
              : undefined
          );
          setStripePromise(stripePromise);
          setLoading(false);
        } else {
          message.error('Get payment info failed');
          // setCheckInfo(res?.data || {currency: 'USD', price: '10.00'});
          // setClientSecret(res?.data?.urlKey || querySecret);
          // setLoading(false);
        }
      })
      .catch(e => {
        message.error('Get payment info failed');
        // setCheckInfo({currency: 'USD', price: '10.00'});
        // setClientSecret(querySecret);
        // setLoading(false);
      });
  }, [transactionId]);

  const appearance = {
    theme: 'stripe',
    variables: {
      colorPrimary: '#ff8f00',
      colorBackground: '#ffffff',
      colorText: 'rgba(0,0,0,0.88)',
      colorDanger: '#df1b41',
      fontFamily: 'Ideal Sans, system-ui, sans-serif',
      spacingUnit: '4px',
      borderRadius: '4px'
      // See all possible variables below
    }
  };
  const options = {
    clientSecret,
    appearance
  };

  return (
    <div className={styles.stripeContainer}>
      {loading ? (
        <Card className={styles.stripeElementContainer}>
          <Skeleton loading active></Skeleton>
          {showReload ? (
            <div>
              <div>
                load stripe failed, please{' '}
                <a onClick={() => window.location.reload()}>try again</a>
              </div>
            </div>
          ) : null}
        </Card>
      ) : (
        clientSecret &&
        ((
          <Card className={styles.stripeElementContainer}>
            <Elements options={options} stripe={stripePromise}>
              <CheckoutForm
                from={from}
                isConfirmReturn={is_confirm_return}
                clientSecret={clientSecret}
                paymentInfo={checkoutInfo}
                transactionId={transactionId}
              />
            </Elements>
            {showReload ? (
              <div>
                <div>
                  load stripe failed, please{' '}
                  <TextButton onClick={() => window.location.reload()}>
                    try again
                  </TextButton>
                </div>
              </div>
            ) : null}
          </Card>
        ) ||
          '')
      )}
    </div>
  );
}
