import React, { useEffect, useState } from 'react';
import {
  PaymentElement,
  useStripe,
  useElements,
  LinkAuthenticationElement
} from '@stripe/react-stripe-js';
import { Button, Skeleton, Result, Spin, message as antMessage } from 'antd';
import TextButton from 'routes/AgencyCostManagement/components/TextButton';
import styles from './Common.less';
import { Post } from 'utils/request';
import { dsersBFFApiHost } from 'common/sysconfig';

const getPaymentCallbackUrl = (paymentStatus, from) => {
  if (from === 'tmall_order') {
    if (paymentStatus === 'succeeded') {
      return `${window.location.origin}/app/order/tmall/list`;
    }
    return null;
  }

  if (from === 'agency_order') {
    if (paymentStatus === 'succeeded') {
      return `${window.location.origin}/app/order/agency/list`;
    }
    return null;
  }

  if (!from) {
    if (paymentStatus === 'succeeded') {
      return `${window.location.origin}/app/`;
    }

    return null;
  }
};

export default function CheckoutForm({
  clientSecret,
  isConfirmReturn,
  from,
  paymentInfo,
  transactionId,
  ...restProps
}) {
  const stripe = useStripe();
  const elements = useElements();
  // 检查payment intent状态 loading
  const [checkIntentLoading, setCheckIntentLoading] = useState(true);
  // payment intent 状态
  // requires_payment_method, requires_confirmation, requires_action, processing, requires_capture, canceled, or succeeded
  const [intentStatus, setIntentStatus] = useState('');
  // 支付报错信息
  const [message, setMessage] = useState(null);
  // 支付按钮loading
  const [isLoading, setIsLoading] = useState(false);
  // 支付组件加载loading
  const [elementLoading, setElementLoading] = useState(true);
  // 邮箱 目前没用
  const [email, setEmail] = useState('');
  // cancel loading
  const [cancelLoading, setCancelLoading] = useState(false);

  // console.log(restProps, stripe);
  useEffect(() => {
    if (!stripe) {
      return;
    }

    if (!clientSecret) {
      return;
    }
    // 检查payment intent状态
    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      if(!paymentIntent) {
        antMessage.error('Invalid payment link');
      }
      try {
        console.log(paymentIntent.status);
        setIntentStatus(paymentIntent.status);
      } catch (error) {
        
      }
      
      // 如果是return url跳回来的就检查下是否需要直接跳转回dsers
      // if (
      //   isConfirmReturn &&
      //   getPaymentCallbackUrl(paymentIntent.status, from)
      // ) {
      //   window.location.href = getPaymentCallbackUrl(
      //     paymentIntent.status,
      //     from
      //   );
      //   return;
      // }
      setCheckIntentLoading(false);
    });
  }, [stripe, isConfirmReturn]);

  const handleSubmit = async e => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${window.location.href}&is_confirm_return=1`
      }
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === 'card_error' || error.type === 'validation_error') {
      setMessage(error.message);
    } else {
      console.log(error);
      setMessage('An unexpected error occurred.');
    }

    setIsLoading(false);
  };

  const handleCancelPayment = async () => {
    setCancelLoading(true);
    try {
      const cancelRes = await Post(`${dsersBFFApiHost}/dsers-pay-bff/v1/pay/cancel`, {
        isCustomApi: true,
        data: {
          transactionId
        }
      });
      console.log(cancelRes)
      if (cancelRes && !cancelRes.code) {
        window.location.href = getPaymentCallbackUrl('succeeded', from);
      }
    } catch (error) {
      console.log(error);
    }

    setCancelLoading(false);
  };

  const paymentElementOptions = {
    layout: 'tabs'
  };

  if (checkIntentLoading) {
    return <Skeleton loading active></Skeleton>;
  }

  if (['canceled', 'succeeded'].includes(intentStatus)) {
    return (
      <Result
        title={
          intentStatus === 'canceled'
            ? 'This payment has been canceled'
            : 'This payment has been compeleted'
        }
        status={intentStatus === 'succeeded' ? 'success' : 'info'}
        extra={[
          <TextButton
            onClick={() =>
              (window.location.href = getPaymentCallbackUrl('succeeded', from))
            }
          >
            Return to DSers
          </TextButton>
        ]}
      ></Result>
    );
  }

  return (
    <Spin spinning={cancelLoading}>
      <form id="stripe-payment-form">
        <LinkAuthenticationElement
          id="link-authentication-element"
          onChange={e => setEmail(e.target?.value)}
        />
        <div style={{ paddingTop: 8, paddingBottom: 8 }}>
          {/* Tips: This email address will be displayed in your payee's collection
          records */}
        </div>
        <PaymentElement
          onReady={() => {
            setElementLoading(false);
          }}
          id="payment-element"
          options={paymentElementOptions}
        />
        {!stripe || !elements || elementLoading ? null : (
          <div style={{ paddingTop: 16, paddingBottom: 16 }}>
            <Button
              type="primary"
              loading={isLoading}
              id="submit"
              onClick={handleSubmit}
              style={{ width: '100%' }}
              size="large"
            >
              <span id="button-text">
                PAY NOW ({`${paymentInfo.currency} ${(paymentInfo.price/100).toFixed(2)}`})
              </span>
            </Button>
          </div>
        )}

        {/* Show any error or success messages */}
        {message && <div id="payment-message">{message}</div>}
        {!stripe || !elements || elementLoading ? null : (
          <div style={{ textAlign: 'center' }}>
            <TextButton onClick={handleCancelPayment}>
              Cancel payment and Return to DSers
            </TextButton>
          </div>
        )}
      </form>
    </Spin>
  );
}
